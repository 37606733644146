.main {
    margin: 50px auto;
    text-align: center;
}

.playfield {
    margin: 20px auto;
    width: 20%;
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    justify-content: center;
    align-items: center;
    font-size: 2rem;
}

.item {
    width: 50px;
}

.result {
    width: 10px;
}

.tile {
    height: 60px;
    width: 60px;
    border: 2px solid gainsboro;
    border-radius: 5px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2rem;
    background-color: white;
}

.submit-field {
    height: 50px;
    margin: 40px auto;
    align-items: center;
}

#submit-button {
    width: 150px;
    height: 50px;
    background-color: white;
    border: 2px solid gainsboro;
    border-radius: 5px;
    font-size: 1.7em;
}

#submit-button:hover {
    cursor: pointer;
}

#submit-button:disabled {
    cursor: default;
    background-color: white;
    border: 2px solid gainsboro;
}

.inventory {
    margin: 0 auto;
    width: 70%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    gap: 30px;
}

.number-tile {
    height: 60px;
    min-width: 60px;
    border: 2px solid gainsboro;
    border-radius: 5px;
    font-size: 2rem;
    background-color: white;
}

@media only screen and (max-width: 1500px) {
    .main {
        margin: 30px auto;
    }

    .submit-field {
        height: 50px;
    }
    
    .inventory {
        width: 80%;
        gap: 25px;
    }
}

@media only screen and (max-width: 1000px) {
    .main {
        margin: 10px auto;
    }

    .playfield {
        width: 80%;
    }

    .item {
        width: 40px;
    }

    .tile {
        height: 50px;
        width: 50px;
        font-size: 1.8rem;
    }
    
    .submit-field {
        height: 40px;
    }

    #submit-button {
        width: 125px;
        height: 40px;
        font-size: 1.5em;
        border-radius: 5px;
    }

    #submit-button:hover {
        background-color: white;
    }
    
    .inventory {
        width: 90%;
        gap: 15px;
    }

    .number-tile {
        height: 50px;
        min-width: 50px;
        font-size: 1.8rem;
    }  
}