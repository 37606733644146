.nav-bar {
    display: flex;
    align-items: center;
    border-bottom: 1px solid gainsboro;
    padding: 10px;
}

.title {
    padding-left: 90px;
    letter-spacing: 2px;
    margin-left: auto;
}

#question-icon {
    width: 30px;
    height: 30px;
    margin-left: auto;
    margin-right: 40px;
    cursor: pointer;
}

#rules-panel {
    position: absolute;
    width: 450px;
    height: 575px;
    top: 33%;
    left: 50%;
    transform: translate(-50%, -33%);
    border-radius: 5px;
    background-color: white;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

#rules-text {
    padding: 20px 5px 20px 20px;
}

#close-icon {
    width: 25px;
    margin: 0 387px;
    cursor: pointer;
}

#rules-text h2 {
    font-size: 1.9em;
}

#rules-text h3 {
    padding-top: 20px;
    font-weight: 400;
    font-size: 1.5em;
}

#rules li {
    margin: 20px;
    font-size: 1.3em;
}

@media only screen and (max-width: 1000px) {
    .title {
        padding-left: 60px;
    }
    
    #question-icon {
        margin-right: 10px;
    }
    
    #rules-panel {
        width: 360px;
        height: 500px;
        top: 50%;
        transform: translate(-50%, -50%);
        overflow: hidden;
    }

    #rules-text {
        padding: 20px;
    }

    #close-icon {
        width: 20px;
        margin: 0 300px;
    }

    #rules-text h2 {
        font-size: 1.5em;
    }
    
    #rules-text h3 {
        padding-top: 10px;
        font-size: 1.2em;
    }
    
    #rules li {
        margin: 10px 0 20px 20px;
        font-size: 1.1em;
    }
}