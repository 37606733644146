.cookie-panel {
    width: 60%;
    position: fixed;
    bottom: 0;
    left: 15%;
    transition: transform 0.5s ease;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    column-gap: 50px;
    border: 2px solid gainsboro;
    border-bottom: none;
    padding: 10px 40px;
    background-color: white;
}

.slide-out {
    transform: translateY(100%);
}

.cookie-panel button {
    height: 40px;
    width: 150px;
    border: none;
    font-size: 1.2em;
    background-color: gainsboro;
}

.cookie-panel button:hover {
    cursor: pointer;
}

@media only screen and (max-width: 1000px) { 
    .cookie-panel {
        width: 100%;
        left: 0;
        justify-content: center;
        column-gap: 20px;
        padding: 5px 0;
        border: none;
        border-top: 2px solid gainsboro;
    }
    
    .cookie-panel p {
        width: 60%;
        font-size: 0.8em;
    }

    .cookie-panel button {
        width: 90px;
    }
}